@font-face {
  font-family: 'Rubik-Black';
  src: url('./fonts/Rubik-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Rubik-Bold';
  src: url('./fonts/Rubik-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Rubik-SemiBold';
  src: url('./fonts/Rubik-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Rubik-Medium';
  src: url('./fonts/Rubik-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Rubik-Regular';
  src: url('./fonts/Rubik-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

:root {
  --bold: #252a3b !important;
  --toastify-color-info: #466fff;
  --toastify-color-success: #7ac31d;
  --toastify-color-warning: #f4c829;
  --toastify-color-error: #f35f33;
  --grey: #ccc;
}


html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  margin: 0;
}

a {
  text-decoration: none;
}

textarea {
  resize: none !important;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
input {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

input:focus,
input:focus-visible,
textarea:focus,
textarea:focus-visible {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.special-label {
  display: none;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.appForm input,
.appForm textarea {
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
  border: 1px solid #E2E6E9;
  margin-bottom: 16px;
}


.appForm .appbtn {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 100px;
}

.appForm textarea {
  height: 200px;
}

.borderRed {
  border-color: #f00;
}


.react-tel-input input:focus {
  box-shadow: none;
  border: 2px solid #1F84C5 !important;
}

.react-tel-input input.form-control {
  outline: none;
  border-radius: 8px;
  border: 1px solid #E2E6E9;
}

button {
  box-shadow: none !important;
  cursor: pointer;
  outline: none !important;
  border: none;
}

.cursor {
  cursor: pointer;
}

.container {
  max-width: 1128px;
  margin: 0 auto;
}

.container_slider {
  /* max-width: 1158px;
  margin: 0 auto; */
}

@media only screen and (max-width: 1200px) and (min-width: 320px) {

  .container,
  .container_slider {
    padding-left: 15px !important;
    padding-right: 15px;
  }
}

.ymaps,
.ymaps-2-1-79-inner-panes,
.ymaps-2-1-79-map {
  border-radius: 20px !important;
}

.react-tel-input input:focus {
  box-shadow: none;
  border: none !important;
}

.ymaps-2-1-79-gototech,
.ymaps-2-1-79-copyright__content {
  display: none !important;
}